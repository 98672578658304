import swal from "sweetalert";
import {
    addEmployee,
    getEmployee,
    updateEmployee,
    removeEmployee,
    updateEmployeeStatus,
    getApprover,
    getTimeKeeper,
    getReviewer,
    getKeeperTeam,
    downloadEmployeeRecord,
    removeMultipleEmployee,
    getReportToEmployee,
    checkEmployeeIDExistance,
    getEmployeeByProjectId,
    getClientEmployee,
    updateClientEmployee
} from '../../services/EmployeeService';

import {
    LOADING_EMPLOYEE,
    ADD_EMPLOYEE,
    GET_EMPLOYEE_LIST,
    GET_EMPLOYEE_LIST_BY_PROJECT_ID,
    UPDATE_EMPLOYEE_STATUS,
    REMOVE_EMPLOYEE,
    GET_APPROVER_LIST,
    GET_REVIEWER_LIST,
    GET_KEEPER_LIST,
    GET_GROUP_KEEPER_LIST,
    GET_REPORT_TO_EMPLOYEE_LIST,
    GET_CLIENT_EMPLOYEE_LIST
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from './GlobalAction';
import { addPrivilegeData } from './RoleActions';


export function addEmployeeData(roleName, roleType, group_id, first_name, last_name, mobile, email, employeeId, approver, reviewer, password, status, user_type, rate, hours,isSupervisor,reportEmp, perPage, history) {
    return (dispatch) => {
        addEmployee(roleName, roleType, group_id, first_name, last_name, mobile, email, employeeId, approver, reviewer, password, status, user_type, rate, hours,isSupervisor,reportEmp)
            .then((response) => {
                // console.log('response...', response.data.data)
                if (response.data.status == 200) {
                    dispatch(getEmployeeList('', '', '', 1, perPage, history));
                    //dispatch(addPrivilegeData(response.data.data, privilege, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function UpdateEmployeeData(emp_id, role_id, roleType, group_id, first_name, last_name, mobile, email, approver, reviewer, password, status, user_type, rate, hours,isSupervisor,reportEmp, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        updateEmployee(emp_id, role_id, roleType, group_id, first_name, last_name, mobile, email, approver, reviewer, password, status, user_type, rate, hours,isSupervisor,reportEmp)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEmployeeList(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                    // dispatch(addPrivilegeData(emp_id, privilege, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getEmployeeList(searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        getEmployee(searchString, orderByField, orderByFieldValue, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEmployeeAction(response.data));
                    dispatch(globalLoadingAction(false));
                } else {
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function getEmployeeListByProjectId(project_id, history) {
    return (dispatch) => {
        getEmployeeByProjectId(project_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEmployeeByProjectAction(response.data));
                    dispatch(globalLoadingAction(false));
                } else {
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getReportToEmployeeList(history) {
    return (dispatch) => {
        getReportToEmployee()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getReportToEmployeeAction(response.data));
                    dispatch(globalLoadingAction(false));
                } else {
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function checkEmployeeExistance(employee_id,callBack) {
    return (dispatch) => {
        checkEmployeeIDExistance(employee_id)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack(response.data.data);
                    dispatch(globalLoadingAction(false));
                } else {
                    callBack(null);
                }
            })
            .catch((error) => {
                dispatch(globalLoadingAction(false));
            });
    };
}
export function updateEmployeeStatusData(item, history) {
    return (dispatch) => {
        updateEmployeeStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateEmployeeStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getClientEmployeeList(history) {
    return (dispatch) => {
        getClientEmployee()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getClientEmployeeAction(response.data));
                    dispatch(globalLoadingAction(false));
                } else {
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateClientEmployeeList(user_id,first_name,last_name,email,country_code,mobile_no,password,history) {
    return (dispatch) => {
        updateClientEmployee(user_id,first_name,last_name,email,country_code,mobile_no,password)
            .then((response) => {
                console.log('helllo wolrd...',response.data);
                if (response.data.status == 200) {
                    dispatch(getClientEmployeeList(history));
                } else {
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getApproverList(history) {
    return (dispatch) => {
        getApprover()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getApproverAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getReviewerList(history) {
    return (dispatch) => {
        getReviewer()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getReviewerAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getTimeKeeperList(history) {
    return (dispatch) => {
        getTimeKeeper()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeKeeperAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getKeeperTeamListData(id, history) {
    return (dispatch) => {
        getKeeperTeam(id)
            .then((response) => {
                // console.log('hello world..',response.data.data);
                if (response.data.status == 200) {
                    dispatch(getKeeperTeamAction(response.data.data));
                    //utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeEmployeeData(id, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeEmployee(id)
            .then((response) => {
                if (response.data.status == 200) {
                    //dispatch(removeEmployeeAction(id));
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getEmployeeList(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeMultipleEmployeeData(userIds, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeMultipleEmployee(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEmployeeList(searchString, orderByField, orderByFieldValue, page_no, per_page));
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(globalLoadingAction(false));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function downloadEmployeeData(userIds, history) {
    return (dispatch) => {
        downloadEmployeeRecord(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    const outputFilename = `${Date.now()}.xls`;
                    const link = document.createElement('a');
                    link.href = response.data.data;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getEmployeeAction(data) {
    return {
        type: GET_EMPLOYEE_LIST,
        payload: data,
    };
}
export function getClientEmployeeAction(data) {
    return {
        type: GET_CLIENT_EMPLOYEE_LIST,
        payload: data,
    };
}
export function getEmployeeByProjectAction(data) {
    return {
        type: GET_EMPLOYEE_LIST_BY_PROJECT_ID,
        payload: data,
    };
}
export function getReportToEmployeeAction(data) {
    return {
        type: GET_REPORT_TO_EMPLOYEE_LIST,
        payload: data,
    };
}
export function updateEmployeeStatusAction(data) {
    return {
        type: UPDATE_EMPLOYEE_STATUS,
        payload: data,
    };
}
export function removeEmployeeAction(data) {
    return {
        type: REMOVE_EMPLOYEE,
        payload: data,
    };
}
export function getApproverAction(data) {
    return {
        type: GET_APPROVER_LIST,
        payload: data,
    };
}
export function getReviewerAction(data) {
    return {
        type: GET_REVIEWER_LIST,
        payload: data,
    };
}
export function getTimeKeeperAction(data) {
    return {
        type: GET_KEEPER_LIST,
        payload: data,
    };
}
export function getKeeperTeamAction(data) {
    return {
        type: GET_GROUP_KEEPER_LIST,
        payload: data,
    };
}
export function loadingEmployeeAction(status) {
    return {
        type: LOADING_EMPLOYEE,
        payload: status,
    };
}
