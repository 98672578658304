import React from "react";
import { Container, ProgressBar } from "react-bootstrap";

const steps = [
    { label: "Generated", icon: "bi bi-journal-check" },
    { label: "Supervisor", icon: "bi bi-person-check" },
    { label: "Reviewer", icon: "bi bi-box-seam" },
    { label: "Approver", icon: "bi bi-shield-check" },
    { label: "Client", icon: "bi bi-people" },
];



const ProgressReport = (props) => {

    const currentStep = props.step;

    const getProgress = (step) => {
        let baseProgress = (step / (steps.length - 1)) * 100;
        const adjustmentMap = { 0: 10, 1: 5, 2: 0, 3: -5 };
        const adjustment = adjustmentMap[step] || 0;

        return Math.min(Math.max(baseProgress + adjustment, 0), 100);
    };

    return (
        <div className="progress-report-container">
            <div className="progress-report">
                <div className="d-flex justify-content-between align-items-center position-relative mb-3">
                    {steps.map((step, index) => (
                        <div key={index} className="text-center flex-fill position-relative">

                            <div
                                className={`rounded-circle d-inline-flex align-items-center justify-content-center
                                 ${index <= currentStep ? "bg-warning text-white" : "bg-light text-dark"}`}
                                style={{
                                    width: "45px",
                                    height: "45px",
                                    border: "2px solid",
                                    borderColor: index <= currentStep ? "orange" : "gray",
                                    fontSize: "20px",
                                }}
                            >
                                <i className={step.icon}></i>
                            </div>
                            {/* Step Label */}
                            <div className="mt-2">{step.label}</div>
                            {/* Connecting Line */}
                            {index < steps.length - 1 && (
                                <div
                                    className="position-absolute top-50 start-100 translate-middle w-100"
                                    style={{
                                        height: "4px",
                                        backgroundColor: index < currentStep ? "orange" : "gray",
                                        zIndex: -1,
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <ProgressBar
                now={getProgress(currentStep)}
                variant="warning"
                className="mt-3"
                style={{ height: "8px" }}
            />

        </div>
    );
};

export default ProgressReport;
