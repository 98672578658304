//////////////Global//////////

export const GLOBAL_LOADING = "GLOBAL_LOADING";

export const GET_COUNTRY = "GET_COUNTRY";

export const GET_STATE = "GET_STATE";

export const GET_CITY = "GET_CITY";

export const COUNTRY_LOADING = "COUNTRY_LOADING";

//////////////Auth//////////

export const LOADING_TOGGLE_ACTION = "LOADING_TOGGLE_ACTION";

export const LOGIN_CONFIRMED_ACTION = "LOGIN_CONFIRMED_ACTION";

export const LOGIN_FAILED_ACTION = "LOGIN_FAILED_ACTION";

export const LOGOUT_ACTION = "LOGOUT_ACTION";

export const SIGNUP_CONFIRMED_ACTION = "SIGNUP_CONFIRMED_ACTION";

export const SIGNUP_FAILED_ACTION = "SIGNUP_FAILED_ACTION";

export const SET_OTP_ACTION = "SET_OTP_ACTION";

export const GET_PROFILE_AND_COMPANY_DETAILS_ACTION = "GET_PROFILE_AND_COMPANY_DETAILS_ACTION";

export const SETUP_USER_DATA = "SETUP_USER_DATA";

export const SETUP_COMPANY_DATA = "SETUP_COMPANY_DATA";

export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";

export const GET_PERMISSION_MENU_DATA = "GET_PERMISSION_MENU_DATA";

//////////////Role-management//////////

export const LOADING_ROLE = "LOADING_ROLE";

export const GET_PERMISSION_LIST = "GET_PERMISSION_LIST";

export const ADD_ROLE = "ADD_ROLE";

export const GET_ROLE = "GET_ROLE";

export const GET_ACTIVE_ROLE = "GET_ACTIVE_ROLE";

export const UPDATE_ROLE_STATUS = "UPDATE_ROLE_STATUS";

export const REMOVE_ROLE = "REMOVE_ROLE";

export const GET_PREVILEDGE = "GET_PREVILEDGE";

//////////////User-management//////////

export const LOADING_EMPLOYEE = "LOADING_EMPLOYEE";

export const ADD_EMPLOYEE = "ADD_EMPLOYEE";

export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";

export const GET_CLIENT_EMPLOYEE_LIST = "GET_CLIENT_EMPLOYEE_LIST";

export const GET_EMPLOYEE_LIST_BY_PROJECT_ID = "GET_EMPLOYEE_LIST_BY_PROJECT_ID";

export const GET_REPORT_TO_EMPLOYEE_LIST = "GET_REPORT_TO_EMPLOYEE_LIST";

export const UPDATE_EMPLOYEE_STATUS = "UPDATE_EMPLOYEE_STATUS";

export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE";

export const GET_APPROVER_LIST = "GET_APPROVER_LIST";

export const GET_REVIEWER_LIST = "GET_REVIEWER_LIST";

export const GET_KEEPER_LIST = "GET_KEEPER_LIST";

export const GET_GROUP_KEEPER_LIST = "GET_GROUP_KEEPER_LIST";

//////////////Profile//////////

export const LOADING_PROFILE = "LOADING_PROFILE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const UPDATE_COMPANY = "UPDATE_COMPANY";

//////////////Subscribtion//////////

export const LOADING_SUBSCRIBTION = "LOADING_SUBSCRIBTION";

export const CHECK_SUBSCRIBTION = "CHECK_SUBSCRIBTION";

export const CANCEL_SUBSCRIBTION = "CANCEL_SUBSCRIBTION";

export const GET_SUBSCRIBTION = "GET_SUBSCRIBTION";

///////////////////////Client/////////////////////

export const LOADING_CLIENTS = "LOADING_CLIENTS";

export const GET_CLIENTS = "GET_CLIENTS";

export const UPDATE_CLIENTS_STATUS = "UPDATE_CLIENTS_STATUS";

export const REMOVE_CLIENTS = "REMOVE_CLIENTS";

///////////////////////Project/////////////////////

export const LOADING_PROJECT = "LOADING_PROJECT";

export const ADD_PROJECT = "ADD_PROJECT";

export const GET_PROJECT = "GET_PROJECT";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";

export const UPDATE_PROJECT_STATUS = "UPDATE_PROJECT_STATUS";

export const REMOVE_PROJECT_DETAILS = "REMOVE_PROJECT_DETAILS";

export const GET_PROJECT_ACTIVITIES = "GET_PROJECT_ACTIVITIES";

export const GET_PROJECT_ACTIVITIES_CHECK_EXISTANCE = "GET_PROJECT_ACTIVITIES_CHECK_EXISTANCE";

export const UPDATE_PROJECT_ACTIVITIES_STATUS = "UPDATE_PROJECT_ACTIVITIES_STATUS";

export const REMOVE_PROJECT_ACTIVITIES_DETAILS = "REMOVE_PROJECT_ACTIVITIES_DETAILS";

export const GET_TASK_LIST = "GET_TASK_LIST";

export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";

export const REMOVE_TASK_LIST = "REMOVE_TASK_LIST";

export const GET_TASK_DETAILS_BY_ID = "GET_TASK_DETAILS_BY_ID";

export const GET_PROJECT_DETAILS_BY_ID = "GET_PROJECT_DETAILS_BY_ID";

///////////////////////Team/////////////////////

export const LOADING_TEAM = "LOADING_TEAM";

export const ADD_TEAM = "ADD_TEAM";

export const GET_TEAM_LIST = "GET_TEAM_LIST";

export const GET_TEAM_LIST_BY_ID = "GET_TEAM_LIST_BY_ID";

export const GET_ACTIVE_TEAM_LIST = "GET_ACTIVE_TEAM_LIST";

export const UPDATE_TEAM_STATUS = "UPDATE_TEAM_STATUS";

export const REMOVE_TEAM_RECORD = "REMOVE_TEAM_RECORD";

///////////////////////Location/////////////////////

export const LOADING_LOCATION = "LOADING_LOCATION";

export const GET_LOCATION_LIST = "GET_LOCATION_LIST";

export const GET_LOCATION_TYPE_LIST = "GET_LOCATION_TYPE_LIST";

export const UPDATE_LOCATION_STATUS = "UPDATE_LOCATION_STATUS";

///////////////////////Time-Capture/////////////////////

export const LOADING_TIME_CAPTURE = "LOADING_TIME_CAPTURE";

export const START_TIME_ACTION = "START_TIME_ACTION";

export const STOP_TIME_ACTION = "STOP_TIME_ACTION";

export const OFFSET_TIME_ACTION = "OFFSET_TIME_ACTION";

export const ADD_TIME_CAPTURE = "ADD_TIME_CAPTURE";

export const UPDATE_TIME_CAPTURE = "UPDATE_TIME_CAPTURE";

export const SET_SINGLE_DATA_OBJECT = "SET_SINGLE_DATA_OBJECT";

export const GET_TIME_CAPTURE = "GET_TIME_CAPTURE";

export const GET_ASSIGNED_TASK_ACTION = "GET_ASSIGNED_TASK_ACTION";

export const GET_TRACKING_DETAILS_ACTION = "GET_TRACKING_DETAILS_ACTION";

export const GET_APPROVED_TRACKING_FOR_APPROVER_ACTION = "GET_APPROVED_TRACKING_FOR_APPROVER_ACTION";

export const GET_REJECT_TRACKING_FOR_APPROVER_ACTION = "GET_REJECT_TRACKING_FOR_APPROVER_ACTION";

export const GET_PENDING_TRACKING_FOR_APPROVER_ACTION = "GET_PENDING_TRACKING_FOR_APPROVER_ACTION";

export const GET_PROJECT_APPROVER_TRACKING_DETAILS_ACTION = "GET_PROJECT_APPROVER_TRACKING_DETAILS_ACTION";

///////////////////////Time-Card/////////////////////

export const LOADING_TIME_CARD = "LOADING_TIME_CARD";

export const ADD_TIME_CARD = "ADD_TIME_CARD";

export const GET_TIME_CARD_LIST = "GET_TIME_CARD_LIST";

export const GET_TIME_CARD_SINGLE_DETAILS = "GET_TIME_CARD_SINGLE_DETAILS";

export const GET_GENERATE_TIME_CARD_DETAILS = "GET_GENERATE_TIME_CARD_DETAILS";

export const GET_GENERATED_REPORTS_DETAILS = "GET_GENERATED_REPORTS_DETAILS";

export const GET_MANUAL_TIMESHEET = "GET_MANUAL_TIMESHEET";

///////////////////////GET-System-Default/////////////////////

export const GET_SYSTEM_DEFAULT_LIST = "GET_SYSTEM_DEFAULT_LIST";

export const GET_SYSTEM_USER_CONNFIC_DETAILS = "GET_SYSTEM_USER_CONNFIC_DETAILS";
