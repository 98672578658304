/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useTimer, useStopwatch } from 'react-timer-hook';
import {
	updateRoleData, addRoleData, loadingRoleAction, getRolePermissionsData
} from '../../../store/actions/RoleActions';
import { addTimeCapture, updateTimeCapture, getTimeCaptureDetails, setSingleDataObject, offsetTimeAction, removeTimeCaptureData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import userImg from "../../../images/avatar.png";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
	componentWillUnmount() {
	}
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const SideBar = () => {

	const dispatch = useDispatch();
	const history = useHistory();
	const profileDetails = useSelector(state => state.auth);
	const companyDetails = useSelector(state => state.auth.company_details);
	const currentPermission = useSelector(state => state.auth.permissionsList);
	const permissionsList = useSelector(state => state.role.permissionsList);
	const userType = useSelector(state => state.auth.userType);
	const userId = useSelector(state => state.auth.userId);
	const empType = useSelector(state => state.auth.empType);
	const timeKepper = useSelector(state => state.auth.timeKepper);
	const systemAdmin = useSelector(state => state.auth.systemAdmin);
	const isSupervisor = useSelector(state => state.auth.isSupervisor);
	const isRunning = useSelector(state => state.timeCapture.isRunning);
	const offset = useSelector(state => state.timeCapture.initialOffset);
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);


	useEffect(() => {
		let intervalId;
		if (isRunning) {
			intervalId = setInterval(() => {
				dispatch(offsetTimeAction(1000));
			}, 1000);
		} else {
			clearInterval(intervalId);
		}
		return () => {
			clearInterval(intervalId);
		};
	}, [isRunning]);


	useEffect(() => {
		var btn = document.querySelector(".nav-control");
		var aaa = document.querySelector("#main-wrapper");
		function toggleFunc() {
			return aaa.classList.toggle("menu-toggle");
		}
		btn.addEventListener("click", toggleFunc);

		//sidebar icon Heart blast
		var handleheartBlast = document.querySelector('.heart');
		function heartBlast() {
			return handleheartBlast.classList.toggle("heart-blast");
		}
		handleheartBlast.addEventListener('click', heartBlast);

	}, []);

	const milliseconds = offset % 1000;
	const seconds = Math.floor((offset / 1000) % 60);
	const minutes = Math.floor((offset / (1000 * 60)) % 60);
	const hours = Math.floor((offset / (1000 * 60 * 60)) % 24);

	//For scroll
	const [hideOnScroll, setHideOnScroll] = useState(true);
	useScrollPosition(
		({ prevPos, currPos }) => {
			const isShow = currPos.y > prevPos.y
			if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
	/// Path
	let path = window.location.pathname;
	path = path.split("/");
	path = path[path.length - 1];

	/// Active menu
	let dashBoard = [
		"",
		"dashboard",
	],
		account = [
			"manage-password",
		],
		timeCapture = [
			"time-capture",
			"task-list",
		],
		taskDetails = [
			"task-details",
			"approver-task",
		],
		supervisorView = [
			"supervisor-time-card",
		],
		settings = [
			"manage-users",
			"role-management",
			"add-role",
			"update-role",
			"copy-role",
			"clients",
			"group",
			"classifications",
			"client-project",
			"project-activities",
			"project-task",
			"location",
			"system-default",
		],
		classifications = [
			"classifications",
			"project-activities",
			"project-task",
			"client-project",
			"client-project-activities",
			"client-project-task",
			"client-activities",
			"client-task",
		],
		security = ["manage-users", "role-management"],
		app = [
			"app-profile",
			"post-details",
		],
		reporting = [
			"time-cart",
			"cart-details",
			"own-cart",
			"real-time-capture",
			"manual-entry",
			"user-time-cart",
			"manual-time-entry",
			"client-time-card",
			"report-to",
			"generated-report",
			"client-reports",
			"client-reviewer",
			"client-approver",
			"supervisor-approval"
		];

	const insertBreaks = (text, interval = 10) => {
		return text.split('').map((char, index) => (
			(index % interval === 0 && index !== 0) ? <React.Fragment key={index}>{char}<wbr /></React.Fragment> : char
		));
	};
	return (
		<div
			className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
				sidebarLayout.value === "horizontal" &&
				headerposition.value === "static"
				? hideOnScroll > 120
					? "fixed"
					: ""
				: ""
				}`}
		>
			<div className="custom-nav-scrollbar">
				{/* <PerfectScrollbar className="dlabnav-scroll"> */}
				<div className="nav-profile">
					<div className="nav-profile-img">
						<div className="nav-profile-img-left">
							<img src={profileDetails.profilePic ? profileDetails.profilePic : userImg} alt="user" />
						</div>
						<div className="nav-profile-img-right">
							<h5>{profileDetails.firstName} {profileDetails.lastName}</h5>
							<p>{insertBreaks(profileDetails.email)}</p>
						</div>
						<div className="nav-profile-edit-icon-view">
							<Link to="/user-profile"><i class="bi bi-pencil-square"></i></Link>
						</div>
					</div>
					{/* <div className="edit-profile-view">
						<Link to="/user-profile"><i class="bi bi-pencil-square"></i> Edit Profile</Link>
					</div> */}
					<div className="nav-profile-menu">
						<ul>
							<li className="pp-active">
								<Link to="/company-profile">{companyDetails.company_name}</Link>
							</li>
							{/* <li><Link>xcvf IT Solution</Link></li> */}
						</ul>
					</div>
				</div>

				<MM className="metismenu" id="menu">

					<li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
						<Link to="/dashboard" className="ai-icon">
							<i class="bi bi-grid"></i>
							<span className="nav-text"> My Dashboard</span>
						</Link>
					</li>

					{currentPermission.includes(2) ?
						<li className={`${account.includes(path) ? "mm-active" : ""}`}>
							<Link className="has-arrow" to="#">
								<i class="bi bi-pencil-square"></i>
								<span className="nav-text">Account</span>
							</Link>
							<ul>
								{currentPermission.includes(3) ?
									<li><Link to="#">User Preferences</Link></li>
									: null}
								{parseInt(userType) == 1 && currentPermission.includes(4) ?
									<li><Link to="#">Subscription</Link></li>
									: null}
								{currentPermission.includes(5) ?
									<li><Link className={`${path === "manage-password" ? "mm-active" : ""}`} to="/manage-password">Manage Password</Link></li>
									: null}
								{currentPermission.includes(6) ?
									<li><Link to="#">Company information</Link></li>
									: null}
							</ul>
						</li>
						: null}

					{currentPermission.includes(7) ?
						<li className={`${timeCapture.includes(path) ? "mm-active" : ""}`}>
							<Link to="/time-capture" className="ai-icon" >
								<i class="bi bi-clock"></i>
								{!timeCapture.includes(path) && isRunning ?
									<span className="nav-text active-time">{hours > 9 ? hours : "0" + hours}:{minutes > 9 ? minutes : "0" + minutes}:{seconds > 9 ? seconds : "0" + seconds}</span>
									:
									<span className="nav-text">Time Capture</span>
								}
							</Link>
						</li>
						: null}

					{parseInt(empType) == 2 ?
						<li className={`${taskDetails.includes(path) ? "mm-active" : ""}`}>
							<Link to="/task-details" className="ai-icon" >
								<i class="bi bi-clock"></i>
								<span className="nav-text">My Reviews</span>
							</Link>
						</li>//reviewer-time-card
						: null}

					{parseInt(empType) == 3 ?
						<li className={`${taskDetails.includes(path) ? "mm-active" : ""}`}>
							<Link to="/approver-task" className="ai-icon" >
								<i class="bi bi-clock"></i>
								<span className="nav-text">My Approvals</span>
							</Link>
						</li>//approver-time-card
						: null}

					{isSupervisor ?
						<li className={`${supervisorView.includes(path) ? "mm-active" : ""}`}>
							<Link to="/supervisor-time-card" className="ai-icon" >
								<i class="bi bi-view-list"></i>
								<span className="nav-text">Report to</span>
							</Link>
						</li>
						: null}

					{parseInt(empType) == 9 || parseInt(empType) == 2 ?
						<li className={`${supervisorView.includes(path) ? "mm-active" : ""}`}>
							<Link to="/client-reviewer" className="ai-icon" >
								<i class="bi bi-view-list"></i>
								<span className="nav-text">Report Approval</span>
							</Link>
						</li>
						: null}

					{parseInt(empType) == 10 || parseInt(empType) == 3 ?
						<li className={`${supervisorView.includes(path) ? "mm-active" : ""}`}>
							<Link to="/client-approver" className="ai-icon" >
								<i class="bi bi-view-list"></i>
								<span className="nav-text">Report Approval</span>
							</Link>
						</li>
						: null}

					{/* <li className={`${supervisorView.includes(path) ? "mm-active" : ""}`}>
						<Link 
							to={{
								pathname: '/client-reports',
								state: { id: userId }
							}}
						 className="ai-icon" >
							<i class="bi bi-view-list"></i>
							<span className="nav-text">Reports</span>
						</Link>
					</li> */}

					<>
						{/* {currentPermission.includes(7) ?
							<li className={`${app.includes(path) ? "mm-active" : ""}`}>
								<Link to="#" className="ai-icon" >
									<i class="bi bi-journal-text"></i>
									<span className="nav-text">Reporting</span>
								</Link>
							</li>
							: null} */}

						{currentPermission.includes(8) ?
							<li className={`${reporting.includes(path) ? "mm-active" : ""}`}>
								<Link className="has-arrow" to="#">
									<i class="bi bi-pencil-square"></i>
									{!reporting.includes(path) && !systemAdmin && isRunning ?
										<span className="nav-text active-time">{hours > 9 ? hours : "0" + hours}:{minutes > 9 ? minutes : "0" + minutes}:{seconds > 9 ? seconds : "0" + seconds}</span>
										:
										<span className="nav-text">Reporting</span>
									}
								</Link>
								<ul>
									{currentPermission.includes(29) ?
										<li>
											{systemAdmin ?
												<Link className={`${path === "manual-time-entry" ? "mm-active" : ""}`}
													to={{
														pathname: '/manual-time-entry',
														state: { id: userId }
													}}
												>Manual entry</Link>
												: <Link className={`${path === "manual-entry" ? "mm-active" : ""}`}
													to={{
														pathname: '/manual-entry',
														state: { id: userId }
													}}
												>Manual entry</Link>}
										</li>
										: null}
									{currentPermission.includes(9) ?
										<li>
											{timeKepper ?
												null
												:
												<Link className={`${path === "time-cart" ? "mm-active" : ""}`} to="/time-cart">Time Entry</Link>
											}
										</li>
										: null}

									{(!systemAdmin && parseInt(empType) !== 4) ?
										<>
											{parseInt(userType) !== 3 && !isSupervisor ?

												<li>
													<Link className={`${path === "real-time-capture" ? "mm-active" : ""}`}
														to="/real-time-capture"
													>Realtime capture</Link>
												</li> : null}

										</>
										: null}

									{currentPermission.includes(10) ?
										<>
											{systemAdmin ?
												<>
													<li>
														<Link className={`${path === "own-cart" ? "mm-active" : ""}`}
															to={{
																pathname: '/own-cart',
																state: { id: userId }
															}}
														>Time Card</Link>
													</li>
												</>
												:
												<>
													{parseInt(userType) == 3 ?
														<>
															<li>
																<Link className={`${path === "client-time-card" ? "mm-active" : ""}`}
																	to={{
																		pathname: '/client-time-card',
																		state: { id: userId }
																	}}
																>Time Card</Link></li>
															<li>
																<Link className={`${path === "client-reports" ? "mm-active" : ""}`}
																	to={{
																		pathname: '/client-reports',
																		state: { id: userId }
																	}}
																>Reports</Link></li>
														</>
														: <li>
															<Link className={`${path === "user-time-cart" ? "mm-active" : ""}`}
																to={{
																	pathname: '/user-time-cart',
																	state: { id: userId }
																}}
															>Time Card</Link></li>}
												</>
											}
											{parseInt(userType) == 1 || parseInt(userType) == 2 ?
												<>
													<li>
														<Link className={`${path === "report-to" ? "mm-active" : ""}`}
															to={{
																pathname: '/report-to',
																state: { id: userId }
															}}
														>Report To</Link>
													</li>
													<li>
														<Link className={`${path === "generated-report" ? "mm-active" : ""}`}
															to={{
																pathname: '/generated-report',
																state: { id: userId }
															}}
														>Generated Report</Link>
													</li>
												</> : null}


										</>

										: null}
									{isSupervisor ?
										<li>
											<Link className={`${path === "supervisor-approval" ? "mm-active" : ""}`}
												to={{
													pathname: '/supervisor-approval',
													state: { id: userId }
												}}
											>Approval List</Link>
										</li> : null}
								</ul>
							</li>
							: null}
					</>

					<>
						{currentPermission.includes(11) ?
							<li className={`${app.includes(path) ? "mm-active" : ""}`}>
								<Link to="#" className="ai-icon" >
									<i class="bi bi-shield-check"></i>
									<span className="nav-text">Approvals</span>
								</Link>
							</li>
							: null}
					</>


					{currentPermission.includes(12) ?
						<li className={`${settings.includes(path) ? "mm-active" : ""}`}>
							<Link className="has-arrow" to="#">
								<i class="bi bi-gear"></i>
								<span className="nav-text">System Settings</span>
							</Link>
							{/* <ul className={`${settings.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}> */}
							<ul>

								{currentPermission.includes(13) ?
									<li><Link to="#">Manage Profile</Link></li>
									: null}
								{/* <li><Link className={`${path === "manage-users" ? "mm-active" : ""}`} to="/manage-users">Manage Users</Link></li>
								<li><Link className={`${path === "role-management" ? "mm-active" : ""}`} to="/role-management">Role Management</Link></li> */}
								{currentPermission.includes(14) ?
									<li><Link className={`${path === "clients" ? "mm-active" : ""}`} to="/clients">Clients</Link></li>
									: null}
								{currentPermission.includes(15) ?
									<li><Link className={`${path === "group" ? "mm-active" : ""}`} to="/group">Group</Link></li>
									: null}
								{currentPermission.includes(16) ?
									<li><Link className={`${path === "location" ? "mm-active" : ""}`} to="/location">Location</Link></li>
									: null}

								{currentPermission.includes(17) ?

									<>
										{parseInt(userType) == 3 ?
											<li className={`${classifications.includes(path) ? "mm-active" : ""}`}>
												<Link className="has-arrow" to="#" >Classifications</Link>
												<ul className={`${classifications.includes(path) ? "mm-show" : ""}`}>
													{currentPermission.includes(18) ?
														<li><Link className={`${path === "client-project" ? "mm-active" : ""}`} to="/client-project">Projects</Link></li>
														: null}
													{currentPermission.includes(19) ?
														<li><Link className={`${path === "client-activities" ? "mm-active" : ""}`} to="/client-activities">Activities</Link></li>
														: null}
													{currentPermission.includes(20) ?
														<li><Link className={`${path === "client-task" ? "mm-active" : ""}`} to="/client-task">Tasks</Link></li>
														: null}
												</ul>
											</li>
											:
											<li className={`${classifications.includes(path) ? "mm-active" : ""}`}>
												<Link className="has-arrow" to="#" >Classifications</Link>
												<ul className={`${classifications.includes(path) ? "mm-show" : ""}`}>
													{currentPermission.includes(18) ?
														<li><Link className={`${path === "classifications" ? "mm-active" : ""}`} to="/classifications">Projects</Link></li>
														: null}
													{currentPermission.includes(19) ?
														<li><Link className={`${path === "activities" ? "mm-active" : ""}`} to="/activities">Activities</Link></li>
														: null}
													{currentPermission.includes(20) ?
														<li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Tasks</Link></li>
														: null}
												</ul>
											</li>
										}
									</>
									: null}

								{/* <li>
									{(() => {
										switch (path) {
											case "classifications":
												return <Link className="mm-active" to="/classifications">Classifications</Link>
											case "project-activities":
												return <Link className="mm-active" to="/classifications">Classifications</Link>
											case "project-task":
												return <Link className="mm-active" to="/classifications">Classifications</Link>
											default:
												return <Link className="" to="/classifications">Classifications</Link>
										}
									})()}
								</li> */}
								{currentPermission.includes(21) ?
									<li><Link className={`${path === "system-default" ? "mm-active" : ""}`} to="/system-default">System Defaults</Link></li>
									: null}

								{currentPermission.includes(22) ?
									<li className={`${security.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Security</Link>
										<ul className={`${security.includes(path) ? "mm-show" : ""}`}>
											{currentPermission.includes(23) ?
												<li>
													{parseInt(userType) == 3 ?
													<Link className={`${path === "client-users" ? "mm-active" : ""}`} to="/client-users">Users</Link>:
													<Link className={`${path === "manage-users" ? "mm-active" : ""}`} to="/manage-users">Users</Link>}
													</li>
												: null}
											{currentPermission.includes(24) ?
												<li><Link className={`${path === "role-management" ? "mm-active" : ""}`} to="/role-management">Role Management</Link></li>
												: null}
										</ul>
									</li>
									: null}

								{currentPermission.includes(25) ?
									<li className=""><Link className="has-arrow" to="#" >Applications</Link>
										<ul className="">
											{currentPermission.includes(26) ?
												<li><Link className="" to="#">Time Reporting</Link></li>
												: null}
											{currentPermission.includes(27) ?
												<li><Link className="" to="#">Performance</Link></li>
												: null}
											{currentPermission.includes(28) ?
												<li><Link className="" to="#">Billing</Link></li>
												: null}
										</ul>
									</li>
									: null}
							</ul>
						</li>
						: null}


					<li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-person-badge"></i>
							<span className="nav-text">Support</span>
						</Link>
					</li>

				</MM>


				<div className="copyright">
					<p className="fs-12"><span className="heart"></span></p>
				</div>
			</div>
			{/* </PerfectScrollbar> */}
		</div>
	);
};

export default SideBar;
