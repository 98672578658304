import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Tab, Nav, Spinner } from "react-bootstrap";
import Moment from 'react-moment';
import "react-datepicker/dist/react-datepicker.css";
import { updateReportDetails, generatedReportDetails } from '../../../store/actions/TimeCardActions';
import { getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import GenerateTimeCard from "../Components/GenerateTimeCard";
import ProgressReport from "../Components/ProgressReport";

const GeneratedReport = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState(1);
    const [reportType, setReportType] = useState(null);
    const [cardList, setCardList] = useState([]);
    const [cardNonList, setCardNonList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [status, setStatus] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(generatedReportDetails(props.history));
    }, []);


    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (task_id) => {
        setProjectId(task_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(task_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onViewHalder = (item) => {
        if (item) {
            let array = JSON.parse(item.report_data);
            if (item.track_type == 0) {
                let array2 = JSON.parse(item.non_billable_report_data);
                setCardNonList(array2);
            }

            setReportId(item._id);
            setStatus(item.status);
            setAddModal(true);
            setCardList(array);
            setDetails(item);
            setReportType(item.track_type);
        }
    }
    const updateCallBack = () => {
        setReportId(null)
        setStatus(null);
        setAddModal(false);
        setLoading(false);
        setCardList([]);
    }
    const onUpdateHandler = () => {
        setLoading(true);
        dispatch(updateReportDetails(reportId, cardList, updateCallBack, props.history));
    }

    const onChangeHandler = (index, value, parentIndex, childIndex) => {
        const updatedData = [...cardList];
        const time = value ? parseInt(value, 10) : 0; // Parse input as integer

        // Update the specific working hourF
        updatedData[index].data_table[parentIndex].time_details[childIndex].working_hour = time;

        // Calculate total hours for the current row
        const totalRowHours = updatedData[index].data_table[parentIndex].time_details.reduce(
            (acc, element) => acc + element.working_hour,
            0
        );
        updatedData[index].data_table[parentIndex].total_hour = totalRowHours;

        // Calculate the overall total hours
        const overallTotalHours = updatedData[index].data_table.reduce(
            (acc, row) => acc + row.total_hour,
            0
        );
        updatedData[index].total_hour = overallTotalHours;
        // Calculate the total pay based on employee rate and total hours   
        const overallTotalPay = updatedData[index].total_hour * (updatedData[index].emp_rate || 0);
        updatedData[index].total_pay = overallTotalPay;
        setCardList(updatedData);
    };

    const onChangeRateHandler = (index, value) => {
        const updatedData = [...cardList];
        const rate = value ? parseInt(value) : 0;
        updatedData[index].emp_rate = rate;
        const overallTotalPay = updatedData[index].total_hour * (updatedData[index].emp_rate || 0);
        updatedData[index].total_pay = overallTotalPay;
        setCardList(updatedData);
    }

    const handleTabSelect = (key) => {
        setActiveTab(Number(key));
    };

    const tabData = [
        {
            id: 1,
            name: "Bilable",
            content: <>{cardList.map((item, index) => {
                return (
                    <GenerateTimeCard
                        key={index}
                        changeHandler={() => { }}
                        changeRateHandler={() => { }}
                        note={false}
                        isEdit={false}
                        details={item} />
                )
            })}</>,
        },
        {
            id: 2,
            name: "Unbilable",
            content: <>{cardNonList.map((item, index) => {
                return (
                    <GenerateTimeCard
                        key={index}
                        changeHandler={() => { }}
                        changeRateHandler={() => { }}
                        note={false}
                        isEdit={false}
                        details={item} />
                )
            })}</>,
        }
    ];

    return (
        <>
            <div className="row">
                {/* {console.log('props.timeCardDetails...',props.timeCardDetails)} */}
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Generated Report</Link>
                        </li>
                    </ul>
                </div>

                <div className="title-body-container">
                    <div className="title-body mb-3">
                        <h3>Generated Report</h3>
                    </div>


                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.timeCardDetails.length > 0 ?
                                            <>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <strong>Report Id</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Client Name</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Project ID</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Report Type</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Date</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Status</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Actions</strong>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            props.timeCardDetails.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.bill_id}</td>
                                                                        <td>{item.client_first_name} {item.client_last_name}</td>
                                                                        <td>{item.project_id}</td>
                                                                        <td>
                                                                            {(() => {
                                                                                switch (item.track_type) {
                                                                                    case 1:
                                                                                        return "Billable"
                                                                                    case 2:
                                                                                        return "Unbillable"
                                                                                    default:
                                                                                        return "All"
                                                                                }
                                                                            })()}
                                                                        </td>
                                                                        <td><Moment format="D MMM YYYY" withTitle>{item.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{item.end_date}</Moment></td>
                                                                        <td>

                                                                            <div className="d-flex">
                                                                                {(() => {
                                                                                    switch (item.status) {
                                                                                        case 1:
                                                                                            return (
                                                                                                <button className="btn btn-warning shadow btn-xs">
                                                                                                    Pending
                                                                                                </button>
                                                                                            );
                                                                                        case 5:
                                                                                            return (
                                                                                                <button className="btn btn-success shadow btn-xs">
                                                                                                    Approved
                                                                                                </button>
                                                                                            );
                                                                                        default:
                                                                                            return (
                                                                                                <button className="btn btn-secondary shadow btn-xs">
                                                                                                    Under Review
                                                                                                </button>
                                                                                            );
                                                                                    }
                                                                                })()}
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <button
                                                                                    onClick={() => onViewHalder(item)}
                                                                                    className="btn btn-info shadow btn-xs"
                                                                                >View
                                                                                </button>

                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                <p>Showing 1 - {props.timeCardDetails.length} of ( {props.timeCardDetails.length} items )</p>
                                            </>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Report data is empty or Try again search</p>
                                                <button type="button"
                                                //onClick={onCleanHandler}
                                                >
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}

                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>

            <Modal
                className="fade bd-example-modal-sm"
                size="xl"
                show={addModal}
                onHide={() => setAddModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h2>Report Card</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {cardList.length > 0 ?
                        <>
                            <div className="card-detail-header">

                                <div className="card-detail-left">
                                    <div>
                                        <h4 className='mb-2'><strong>Project ID:</strong> {details?.project_id}</h4>
                                    </div>
                                    <div>
                                        <h4 className='mb-2'><strong>Report ID:</strong> {details?.bill_id}</h4>
                                    </div>
                                    <div>
                                        <h4 className='mb-2'><strong>Date:</strong><Moment format="D MMM YYYY" withTitle>{details?.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{details?.end_date}</Moment></h4>
                                    </div>

                                </div>
                                <div className="card-detail-right">
                                    <div>
                                        <h4 className='mb-2'><strong>Employee ID:</strong> {details?.employee_id}</h4>
                                    </div>
                                    <div>
                                        <h4 className='mb-2'><strong>Employee Name:</strong> {details?.employee_first_name} {details?.employee_last_name}</h4>
                                    </div>
                                    <div>
                                        <h4 className='mb-b'><strong>Employee Email:</strong> {details?.employee_email}</h4>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <ProgressReport step={details?.status -1} />
                            </div>
                            {reportType == 0 ?
                                <div className='report-tabview'>
                                    <Tab.Container
                                        defaultActiveKey={activeTab}
                                        onSelect={handleTabSelect}
                                    >
                                        <Nav as="ul" className="nav-pills mb-4 light">
                                            {tabData.map((data, i) => (
                                                <Nav.Item as="li" key={i}>
                                                    <Nav.Link eventKey={data.id}>
                                                        {data.name}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                        <Tab.Content className="">
                                            {tabData.map((data, i) => (
                                                <Tab.Pane
                                                    eventKey={data.id}
                                                    key={i}
                                                >
                                                    {activeTab === data.id && data.content}
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Tab.Container>
                                </div> :
                                <>
                                    {cardList.map((item, index) => {
                                        return (
                                            <GenerateTimeCard
                                                key={index}
                                                changeHandler={(value, childIndex, timesIndex) => onChangeHandler(index, value, childIndex, timesIndex)}
                                                changeRateHandler={(value) => onChangeRateHandler(index, value)}
                                                note={false}
                                                ///isEdit={status == 1 ? true : false}
                                                isEdit={false}
                                                details={item} />
                                        )
                                    })} </>}


                        </>
                        :
                        <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                </Modal.Body>
                <Modal.Footer>
                    {/* {status == 1 ?
                        <Button
                            variant="success"
                            onClick={() => onUpdateHandler()}>
                            Update {" "}
                            {loading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : null}
                        </Button>
                        : null} */}
                    <Button variant="secondary" onClick={() => setAddModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCard.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.generatedDetails,
        configDetails: state.systemconfig.configDetails,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        userId: state.auth.userId,
        employeeList: state.employee.projectEmployeeList,
    };
};
export default connect(mapStateToProps)(GeneratedReport);