import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Accordion,
} from "react-bootstrap";
import Moment from 'react-moment';
import swal from "sweetalert";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { getClientEmployeeList, updateClientEmployeeList } from '../../../store/actions/EmployeeActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { validateEmail, validatePassword } from '../../../utility/Utility';
import { getCountryList, countryLoadingAction } from '../../../store/actions/CountryAction';


const ClientUsers = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let errorsObj = { fName: '', lName: '', mobile: '', email: '', password: '', code: '' }
    const [errors, setErrors] = useState(errorsObj);

    const [recId, setRecId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [password, setPassword] = useState('');
    const [conPassword, setConPassword] = useState('');
    const [pws, setPws] = useState(false);
    const [conPws, setConPws] = useState(false);
    const [addModal, setAddModal] = useState(false);

    //filter
    const [searchText, setSearchText] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getClientEmployeeList(props.history));
    }, []);


    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (firstName === '') {
            errorObj.fName = 'First name is Required';
            error = true;
        }
        if (lastName === '') {
            errorObj.lName = 'Last name is Required';
            error = true;
        }
        if (mobile === '') {
            errorObj.mobile = 'Mobile is Required';
            error = true;
        }
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        } else if (validateEmail(email) == true) {
            errorObj.email = 'Invalid Email Id';
            error = true;
        }
        if (password !== conPassword) {
            errorObj.password = 'Password and confirm password does not match';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        setAddModal(false);
        dispatch(globalLoadingAction(true));
        dispatch(updateClientEmployeeList(recId, firstName, lastName, email, '+1', mobile,password, props.history));

    }



    const onCleanHandler = () => {

    }

    const onSearchHandler = (val) => {

    }

    const onSordByHandler = (val) => {

    }

    const onSordHandler = () => { }
    const onAddHandler = () => { }
    const onStatusHandler = () => { }

    const onEditHandler = (item) => {
        console.log('item...', item)
        setRecId(item._id);
        setFirstName(item.first_name);
        setLastName(item.last_name);
        setMobile(item.phone_no);
        setEmail(item.email);
        setPassword('');
        setConPassword('');
        setAddModal(true);
    }

    return (
        <>
            <div className="row">
                {/* {console.log('props.employeeList...', props.employeeList)} */}
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>System Setting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Users</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>Users</h3>
                        <p>Maintain user information - Add, edit, delete and invite users</p>
                    </div>
                    <div className="title-filter-container">
                        <div className="search-filter">
                            <input placeholder="Search"
                                value={searchText}
                                onChange={(e) => onSearchHandler(e.target.value)}
                            />
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>

                        <div className="sort-filter">
                            <select
                                defaultValue={"option"}
                                className=""
                                value={sordBy}
                                onChange={(e) => onSordByHandler(e.target.value)}>
                                <option>Sort by</option>
                                <option value="first_name">Name</option>
                                <option value="email">Email</option>
                                <option value="phone_no">Mobile</option>
                                {/* <option value="user type">User type</option> */}
                            </select>
                            <button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
                                <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="button-filter">
                            <button className="add-user-btn" onClick={() => onAddHandler()}><i class="fa fa-plus" aria-hidden="true"></i></button>
                            <button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <div className="add-role-button-view">
                                    <div className="add-btn-group">
                                        <button disabled={isDisabled}><i class="fa fa-external-link" aria-hidden="true"></i></button>
                                        <button
                                            //onClick={() => onMultipleRemoveHandler()}
                                            disabled={isDisabled}>X</button>
                                        <button disabled={isDisabled}>S</button>
                                        <button
                                            //onClick={() => onDownloadHandler()}
                                            disabled={isDisabled}>
                                            <i class="fa fa-download" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.employeeList.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>ID</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Name</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Email</strong>
                                                        </th>
                                                        <th>
                                                            <strong>User type</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Date</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Actions</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.employeeList.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.employee_id}</td>
                                                                    <td>{item.first_name + " " + item.last_name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.user_type}</td>
                                                                    <td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>

                                                                    <td>
                                                                        <div className="d-flex">

                                                                            <Link
                                                                                onClick={() => onEditHandler(item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Customer type data is empty or Try again search</p>
                                                <button type="button" onClick={onCleanHandler}>
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}

                            </Card.Body>
                        </Card>
                    </Col>
                </div>

                <Modal
                    className="fade bd-example-modal-sm"
                    size="lg"
                    show={addModal}
                    onHide={() => setAddModal(false)}
                >
                    <div className="add-user-modal-form-view client-modal">
                        <button onClick={() => setAddModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                        <div className="modal-title-view">
                            <h3>{"Update User"}</h3>
                            <p>Manage Company/Organization Information such as name, type, location address and contacts.
                            </p>
                        </div>
                        <div className="basic-form">
                            <form onSubmit={onSubmit}>

                                <div className="row">
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            First Name
                                        </label>
                                        <input className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        {errors.fName && <div className="text-danger fs-12">{errors.fName}</div>}
                                    </div>
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Last Name
                                        </label>
                                        <input className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        {errors.lName && <div className="text-danger fs-12">{errors.lName}</div>}
                                    </div>
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Email
                                        </label>
                                        <input className="form-control"
                                            value={email}
                                            //readOnly={action == 2 ? true : false}
                                            //onKeyUp={(e) => onKeyUpEmailHandler(e.target.value)}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                    </div>
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Mobile
                                        </label>
                                        <input className="form-control" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                        {errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Password
                                        </label>
                                        <div className="form-group-pws">
                                            <input type={pws ? 'text' : 'password'} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <div className="pws-icon-view" onClick={() => setPws(!pws)}>
                                                {pws ?
                                                    <i class="bi bi-eye"></i>
                                                    :
                                                    <i class="bi bi-eye-slash"></i>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Confirm Password
                                        </label>
                                        <div className="form-group-pws">
                                            <input type={conPws ? 'text' : 'password'} className="form-control" value={conPassword} onChange={(e) => setConPassword(e.target.value)} />
                                            <div className="pws-icon-view" onClick={() => setConPws(!conPws)}>
                                                {conPws ?
                                                    <i class="bi bi-eye"></i>
                                                    :
                                                    <i class="bi bi-eye-slash"></i>
                                                }
                                            </div>
                                        </div>
                                        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                    </div>


                                </div>
                                <div className="modal-button-group mt-4">
                                    <button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary role-form-btn">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </Modal>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.employee.screenVisible,
        showLoading: state.employee.showLoading,
        employeeList: state.employee.clientUserList,
    };
};
export default connect(mapStateToProps)(ClientUsers);